<template>
<div :key="`key_port_${key}`">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    <span
                        class="btn btn-icon btn-sm Blue"
                        style="cursor: default !important;"
                    >
                        <span
                            class="course-icon kt-font-bolder"
                            style="font-size: 1rem;"
                        >
                            {{ teacher.lastName.substring(0, 2) }}
                        </span>
                    </span>

                    {{ `${teacher.lastName}, ${teacher.firstName}` }}
                </h3>

                <SubHeaderMarkingPeriodPicker />
            </div>
        </div>
    </div>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <TeacherHeaderPortlet
            :show-portlet-header="true"
            :teacher="teacher"
            :teacher-courses="teacherCourses"
        />

        <TeacherCourseListPortlet :teacher="teacher" :teacher-courses="teacherCourses" />

        <TeacherCourseSectionMeetings :teacher="teacher" :teacher-courses="teacherCourses" />
    </div>
</div>
</template>

<script lang="ts">

import userMixins from '../store/mixins/userMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import courseMixins from '../store/mixins/courseMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import TeacherCourseListPortlet from '../components/TeacherCourseListPortlet.vue';
import TeacherCourseSectionMeetings from '../components/TeacherCourseSectionMeetings.vue';
import TeacherHeaderPortlet from '../components/TeacherHeaderPortlet.vue';
import SubHeaderMarkingPeriodPicker from '../components/SubHeaderMarkingPeriodPicker.vue';
import Types from '../store/Types';

export default {
    name: 'TeacherInfo',
    components: {
        TeacherCourseListPortlet,
        TeacherCourseSectionMeetings,
        TeacherHeaderPortlet,
        SubHeaderMarkingPeriodPicker,
    },
    mixins: [teacherMixins, userMixins, courseMixins, portfolioMixins],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        userName() {
            return this.$_userMixins_schoolEmail;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        teacherCourses() {
            const { schoolStaffId } = this.teacher;
            return this.$_courseMixins_getReducedCoursesForStaffId(schoolStaffId).sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
        },
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
    },
    watch: {
        '$route.params.schoolEmail': function () {
            const { userName, $store } = this;
            const { schoolEmail } = this.$route.params;

            // remember the recent portfolio in the left nav menu
            if (schoolEmail != userName) {
                $store.commit(Types.mutations.ADD_PORTFOLIO_HISTORY, {
                    type: 'Teacher',
                    schoolEmail,
                });
            }
            this.key += 1;
        },
    },
};
</script>
