<template>
<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Course List
                <small>{{ teacher.lastName }}, {{ teacher.firstName }}</small>
            </h3>
        </div>
        <div
            v-if="false"
            class="kt-portlet__head-toolbar"
        >
            <div class="kt-portlet__head-actions">
                <a
                    href="#"
                    class="btn btn-primary btn-sm btn-bold"
                    @click.stop.prevent="refresh"
                >
                    Refresh All Courses
                </a>
            </div>
        </div>
    </div>
    <div class="kt-portlet__body pt-0">
        <div class="kt-widget4">
            <TeacherCourseListRow
                v-for="(course, idx) in teacherCourses"
                :key="`courseList_${idx}_${course.extCourseSectionId}`"
                :course="course"
                :teacher="teacher"
            />
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import Types from '../store/Types';
import TeacherCourseListRow from './TeacherCourseListRow.vue';

export default Vue.extend({
    name: 'TeacherCourseListPortlet',
    components: {
        TeacherCourseListRow,
    },
    mixins: [],
    props: {
        teacher: {
            type: Object,
            required: true,
        },
        teacherCourses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
    },
    watch: {
        '$route.params.schoolEmail': {
            handler() {
                this.key += 1;
            },
        },
    },
    mounted() {

    },
    methods: {
        refresh() {
            const { showError } = this;
            this.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSES_FOR_TEACHER, (err) => {
                if (err) return showError(err);
            });
        },
        onSelected() {

        },
    },
});
</script>
