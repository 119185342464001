var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_port_${_vm.key}` }, [
    _c("div", { staticClass: "kt-subheader kt-grid__item" }, [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c(
          "div",
          { staticClass: "kt-subheader__main" },
          [
            _c("h3", { staticClass: "kt-subheader__title" }, [
              _c(
                "span",
                {
                  staticClass: "btn btn-icon btn-sm Blue",
                  staticStyle: { cursor: "default !important" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "course-icon kt-font-bolder",
                      staticStyle: { "font-size": "1rem" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.teacher.lastName.substring(0, 2)) + " "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(
                " " +
                  _vm._s(`${_vm.teacher.lastName}, ${_vm.teacher.firstName}`) +
                  " "
              ),
            ]),
            _c("SubHeaderMarkingPeriodPicker"),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("TeacherHeaderPortlet", {
          attrs: {
            "show-portlet-header": true,
            teacher: _vm.teacher,
            "teacher-courses": _vm.teacherCourses,
          },
        }),
        _c("TeacherCourseListPortlet", {
          attrs: {
            teacher: _vm.teacher,
            "teacher-courses": _vm.teacherCourses,
          },
        }),
        _c("TeacherCourseSectionMeetings", {
          attrs: {
            teacher: _vm.teacher,
            "teacher-courses": _vm.teacherCourses,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }