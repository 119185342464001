<template>
<div class="row w-100 py-1 px-3 my-2">
    <div :class="{'col-4': totals && canViewTotals, 'col-8': !totals || !canViewTotals}">
        <div class="media my-0">
            <span class="kt-media kt-media--md kt-margin-r-5 kt-margin-t-5 align-self-center mr-3">
                <CourseIcon
                    :course="course"
                    size="md"
                />
            </span>
            <div class="media-body ml-3 mt-1 mb-0">
                <router-link
                    v-if="course.teachers.length == 0"
                    :to="{name: 'CourseInfo', params: { extCourseSectionId: course.extCourseSectionId }}"
                    class="course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light"
                >
                    {{ course.name }}
                </router-link>
                <router-link
                    v-else
                    :to="{name: 'TeacherCourseInfo', params: { extCourseSectionId: course.extCourseSectionId, schoolEmail: getFirstTeacherEmail() }}"
                    class="course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light"
                >
                    {{ course.name }}
                </router-link>
                <div
                    v-if="course.teachers.length == 0"
                    class="text-muted"
                >
                    No Teachers
                </div>
                <div
                    v-else
                    class="text-muted"
                >
                    <router-link
                        v-for="teacher in course.teachers"
                        :key="`${teacher.schoolStaffId}_teacher`"
                        :to="{name: 'TeacherCourseInfo', params: { extCourseSectionId: course.extCourseSectionId, schoolEmail: teacher.schoolEmail }}"
                        class="mr-3 kt-link kt-font-dark"
                    >
                        {{ teacher.lastName }}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="totals && canViewTotals"
        class="col-5 kt-widget1 py-0"
    >
        <div class="kt-widget1__item">
            <span
                v-b-tooltip.hover="`${totals.categorizedAssignments} Categorized Assignments, ${totals.uncategorizedAssignments} Uncategorized Assignments`"
                :title="`${totals.totalAssignments} assignments`"
            >
                <span
                    class="kt-widget1__number"
                    :class="{
                        'kt-font-danger': totals.totalAssignments == 0 || totals.categorizedAssignments / totals.totalAssignments < 0.6,
                        'kt-font-warning': totals.categorizedAssignments / totals.totalAssignments < 0.85,
                        'kt-font-success': totals.categorizedAssignments / totals.totalAssignments >= 0.85,
                    }"
                >
                    {{ totals.totalAssignments }}
                </span>
                <span class="text-muted">
                    {{ totals.totalAssignments == 1 ? 'assignment' : 'assignments' }}
                </span>
            </span>
            <span
                v-b-tooltip.hover="`${totals.completedReportCards} report cards graded for ${totals.totalSectionStudents} students`"
                :title="`${totals.completedReportCards} report cards completed`"
            >
                <span
                    class="kt-widget1__number"
                    :class="{
                        'kt-font-danger': (totals.completedReportCards == 0 && totals.totalSectionStudents > 0) || (totals.totalSectionStudents > 0 && totals.completedReportCards / totals.totalSectionStudents < 0.6),
                        'kt-font-warning': totals.totalSectionStudents == 0 || totals.completedReportCards / totals.totalSectionStudents < 0.85,
                        'kt-font-success': totals.totalSectionStudents > 0 && totals.completedReportCards / totals.totalSectionStudents >= 0.85,
                    }"
                >
                    {{ totals.completedReportCards }}
                </span>
                <span class="text-muted">
                    {{ totals.completedReportCards == 1 ? 'report card grade' : 'report card grades' }}
                </span>
            </span>
            <span
                v-b-tooltip.hover="`${totals.totalSectionStudents} students in course section`"
                :title="`${totals.completedReportCards} Students`"
            >
                <span
                    class="kt-widget1__number"
                    :class="{
                        'kt-font-warning': totals.totalSectionStudents == 0,
                        'kt-font-success': totals.totalSectionStudents > 0,
                    }"
                >
                    {{ totals.totalSectionStudents }}
                </span>
                <span class="text-muted">
                    {{ totals.totalSectionStudents == 1 ? 'student' : 'students' }}
                </span>
            </span>
        </div>
    </div>
    <div
        class="text-right mt-1"
        :class="{'col-3': totals && canViewTotals, 'col-4': !totals || !canViewTotals}"
    >
        <div v-if="course.nextMeeting && course.nextMeeting.day">
            {{ course.nextMeeting.day.substring(0, 3) }},
            Period {{ course.nextMeeting.period }},
            Room {{ course.nextMeeting.room }}
        </div>
        <div v-if="googleCourse">
            Linked to
            <a
                v-if="googleCourse.alternateLink"
                target="_blank"
                :href="googleCourse.alternateLink"
            >
                {{ googleCourse.googleCourseName }}
            </a>
            <span v-else>
                {{ googleCourse.googleCourseName }}
            </span>
        </div>
        <div v-else>
            No Google Link
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import courseMixins from '../store/mixins/courseMixins';
import CourseIcon from './CourseIcon.vue';

export default {
    name: 'TeacherCourseListRow',
    components: { CourseIcon },
    mixins: [
        courseMixins,
        googleCourseMixins,
    ],
    props: {
        course: {
            type: Object,
            required: true,
        },
        teacher: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        googleCourse() {
            const { googleCourseId } = this.course;
            if (!googleCourseId) return null;
            return this.$_googleCourseMixins_getGoogleCourseById(googleCourseId);
        },
        teachers() {
            const { course } = this;
            if (!course.teachers) return '';
            return course.teachers
                .map((t) => `${t.lastName} ${t.firstName.substring(0, 1)}`)
                .join(', ');
        },
        color() {
            const { course } = this;
            return this.$_courseMixins_getColorForCourse(course);
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        totals() {
            const { teacher, course, schoolTermMarkingPeriodId } = this;
            const { schoolStaffId } = teacher;
            const courseTeacher = course.teachers.find((t) => t.schoolStaffId == schoolStaffId);
            if (!courseTeacher) return null;
            return courseTeacher.courseSectionTotals.find((t) => t.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId) || null;
        },
        canViewTotals() {
            const { user, teacher } = this;
            const { userPermissions, school } = user;
            const { schoolStaffId } = school;
            return userPermissions.accessAllTeacherPortfolios || teacher.schoolStaffId == schoolStaffId;
        },
    },
    methods: {
        getFirstTeacherEmail() {
            const [teacher] = this.course.teachers;
            return teacher ? teacher.schoolEmail : null;
        },
    },
};
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>

.course-icon {
    display: block;
    font-size: 1.1rem;
    font-weight: 500
}

</style>
