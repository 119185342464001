var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row w-100 py-1 px-3 my-2" }, [
    _c(
      "div",
      {
        class: {
          "col-4": _vm.totals && _vm.canViewTotals,
          "col-8": !_vm.totals || !_vm.canViewTotals,
        },
      },
      [
        _c("div", { staticClass: "media my-0" }, [
          _c(
            "span",
            {
              staticClass:
                "kt-media kt-media--md kt-margin-r-5 kt-margin-t-5 align-self-center mr-3",
            },
            [_c("CourseIcon", { attrs: { course: _vm.course, size: "md" } })],
            1
          ),
          _c(
            "div",
            { staticClass: "media-body ml-3 mt-1 mb-0" },
            [
              _vm.course.teachers.length == 0
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light",
                      attrs: {
                        to: {
                          name: "CourseInfo",
                          params: {
                            extCourseSectionId: _vm.course.extCourseSectionId,
                          },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
                  )
                : _c(
                    "router-link",
                    {
                      staticClass:
                        "course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light",
                      attrs: {
                        to: {
                          name: "TeacherCourseInfo",
                          params: {
                            extCourseSectionId: _vm.course.extCourseSectionId,
                            schoolEmail: _vm.getFirstTeacherEmail(),
                          },
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
                  ),
              _vm.course.teachers.length == 0
                ? _c("div", { staticClass: "text-muted" }, [
                    _vm._v(" No Teachers "),
                  ])
                : _c(
                    "div",
                    { staticClass: "text-muted" },
                    _vm._l(_vm.course.teachers, function (teacher) {
                      return _c(
                        "router-link",
                        {
                          key: `${teacher.schoolStaffId}_teacher`,
                          staticClass: "mr-3 kt-link kt-font-dark",
                          attrs: {
                            to: {
                              name: "TeacherCourseInfo",
                              params: {
                                extCourseSectionId:
                                  _vm.course.extCourseSectionId,
                                schoolEmail: teacher.schoolEmail,
                              },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(teacher.lastName) + " ")]
                      )
                    }),
                    1
                  ),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm.totals && _vm.canViewTotals
      ? _c("div", { staticClass: "col-5 kt-widget1 py-0" }, [
          _c("div", { staticClass: "kt-widget1__item" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: `${_vm.totals.categorizedAssignments} Categorized Assignments, ${_vm.totals.uncategorizedAssignments} Uncategorized Assignments`,
                    expression:
                      "`${totals.categorizedAssignments} Categorized Assignments, ${totals.uncategorizedAssignments} Uncategorized Assignments`",
                    modifiers: { hover: true },
                  },
                ],
                attrs: { title: `${_vm.totals.totalAssignments} assignments` },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "kt-widget1__number",
                    class: {
                      "kt-font-danger":
                        _vm.totals.totalAssignments == 0 ||
                        _vm.totals.categorizedAssignments /
                          _vm.totals.totalAssignments <
                          0.6,
                      "kt-font-warning":
                        _vm.totals.categorizedAssignments /
                          _vm.totals.totalAssignments <
                        0.85,
                      "kt-font-success":
                        _vm.totals.categorizedAssignments /
                          _vm.totals.totalAssignments >=
                        0.85,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.totals.totalAssignments) + " ")]
                ),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.totals.totalAssignments == 1
                          ? "assignment"
                          : "assignments"
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: `${_vm.totals.completedReportCards} report cards graded for ${_vm.totals.totalSectionStudents} students`,
                    expression:
                      "`${totals.completedReportCards} report cards graded for ${totals.totalSectionStudents} students`",
                    modifiers: { hover: true },
                  },
                ],
                attrs: {
                  title: `${_vm.totals.completedReportCards} report cards completed`,
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "kt-widget1__number",
                    class: {
                      "kt-font-danger":
                        (_vm.totals.completedReportCards == 0 &&
                          _vm.totals.totalSectionStudents > 0) ||
                        (_vm.totals.totalSectionStudents > 0 &&
                          _vm.totals.completedReportCards /
                            _vm.totals.totalSectionStudents <
                            0.6),
                      "kt-font-warning":
                        _vm.totals.totalSectionStudents == 0 ||
                        _vm.totals.completedReportCards /
                          _vm.totals.totalSectionStudents <
                          0.85,
                      "kt-font-success":
                        _vm.totals.totalSectionStudents > 0 &&
                        _vm.totals.completedReportCards /
                          _vm.totals.totalSectionStudents >=
                          0.85,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.totals.completedReportCards) + " ")]
                ),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.totals.completedReportCards == 1
                          ? "report card grade"
                          : "report card grades"
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: `${_vm.totals.totalSectionStudents} students in course section`,
                    expression:
                      "`${totals.totalSectionStudents} students in course section`",
                    modifiers: { hover: true },
                  },
                ],
                attrs: { title: `${_vm.totals.completedReportCards} Students` },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "kt-widget1__number",
                    class: {
                      "kt-font-warning": _vm.totals.totalSectionStudents == 0,
                      "kt-font-success": _vm.totals.totalSectionStudents > 0,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.totals.totalSectionStudents) + " ")]
                ),
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.totals.totalSectionStudents == 1
                          ? "student"
                          : "students"
                      ) +
                      " "
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "text-right mt-1",
        class: {
          "col-3": _vm.totals && _vm.canViewTotals,
          "col-4": !_vm.totals || !_vm.canViewTotals,
        },
      },
      [
        _vm.course.nextMeeting && _vm.course.nextMeeting.day
          ? _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.course.nextMeeting.day.substring(0, 3)) +
                  ", Period " +
                  _vm._s(_vm.course.nextMeeting.period) +
                  ", Room " +
                  _vm._s(_vm.course.nextMeeting.room) +
                  " "
              ),
            ])
          : _vm._e(),
        _vm.googleCourse
          ? _c("div", [
              _vm._v(" Linked to "),
              _vm.googleCourse.alternateLink
                ? _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: _vm.googleCourse.alternateLink,
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.googleCourse.googleCourseName) + " "
                      ),
                    ]
                  )
                : _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.googleCourse.googleCourseName) + " "
                    ),
                  ]),
            ])
          : _c("div", [_vm._v(" No Google Link ")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }