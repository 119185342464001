<template>
<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon pr-0">
                <SVGIcon
                    hex-color="#0f88ef"
                    class="mr-3"
                    name="calendar"
                />
            </span>
            <h3 class="kt-portlet__head-title">
                <span>
                    Teacher Schedule
                </span>
            </h3>
        </div>
    </div>

    <div class="kt-portlet__body">
        <div class="kt-section__content pt-3">
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Period</th>
                            <th
                                v-for="(day, idx) in days"
                                :key="`${day}_${idx}`"
                            >
                                <div class="text-center">
                                    {{ day }}
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(row, rowIndex) in tableData"
                            :key="`row_${row.period}_${rowIndex}`"
                        >
                            <td>
                                <div class="text-center mt-2">
                                    <span
                                        class="btn btn-md btn-icon"
                                        style="cursor: default !important;"
                                        :class="[
                                            row.meetings.find((m) => m.course) ? row.color : ''
                                        ]"
                                    >
                                        <span
                                            v-if="row.meetings.find((m) => m.course)"
                                            :style="{color: row.hexColor}"
                                        >
                                            {{ row.period }}
                                        </span>
                                        <span v-else>
                                            {{ row.period }}
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td
                                v-for="(meeting, meetingIdx) in row.meetings"
                                :key="`row_${row.period}_${rowIndex}_${meeting.day}_${meetingIdx}`"
                            >
                                <router-link
                                    v-if="meeting.course && meeting.course.teachers.length > 0"
                                    :to="{
                                        name: 'TeacherCourseInfo',
                                        params: {
                                            schoolEmail: meeting.course.teachers[0].schoolEmail,
                                            extCourseSectionId: meeting.course.extCourseSectionId,
                                        },
                                    }"
                                    class="btn btn-clean"
                                    :class="'btn-clean-' + row.color"
                                >
                                    {{ meeting.course.extCourseSectionId }}
                                    <br>
                                    {{ meeting.course.name }}
                                    <br>
                                    {{ meeting.room }}
                                </router-link>
                                <span
                                    v-else
                                    class="text-muted"
                                > - </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
// @ts-ignore
import cssVars from '../css/colors.scss';

export default Vue.extend({
    name: 'TeacherCourseSectionMeetings',
    mixins: [],
    props: {
        teacher: {
            type: Object,
            required: true,
        },
        teacherCourses: {
            type: Array,
            required: true,
        },
    },
    computed: {
        courseMeetings() {
            let output = [];
            this.teacherCourses.forEach((c) => {
                if (c.courseMeetings) {
                    const rows = c.courseMeetings.map((m) => {
                        const meeting = { ...m }; // new pointer
                        meeting.course = { ...c }; // new pointer
                        return meeting;
                    });
                    output = [
                        ...rows,
                        ...output,
                    ];
                }
            });
            return output;
        },
        days() {
            const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
            // this adds in sat & sun, if the course meets
            const { courseMeetings } = this;
            courseMeetings.forEach((m) => {
                const shortName = m.day.substring(0, 3);
                if (!dayNames.includes(shortName)) {
                    dayNames.push(shortName);
                }
            });
            return dayNames;
        },
        periods() {
            const periods = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
            // const periods = [];
            const { courseMeetings } = this;
            courseMeetings.forEach((m) => {
                if (!periods.includes(m.period)) {
                    periods.push(m.period);
                }
            });
            return periods;
        },
        tableData() {
            const { periods, days, courseMeetings } = this;
            const rows = [];
            periods.forEach((period) => {
                const digit = period ? (period || '0').slice(-1) : '0'; // last char
                const color = period ? `color${digit}` : 'color0';
                const hexColor = cssVars[color];

                const row = {
                    color,
                    hexColor,
                    period,
                    meetings: [],
                };
                days.forEach((day) => {
                    const meeting = courseMeetings.find((m) => m.period == period && m.day.substring(0, 3) == day);
                    row.meetings.push({
                        day,
                        course: meeting ? meeting.course : null,
                        room: meeting ? meeting.room : null,
                    });
                });
                rows.push(row);
            });
            return rows;
        },
    },
});
</script>

<style scoped src='../css/colors.scss' lang="scss" />
<style scoped>
    .table td, .table th {
        vertical-align: center;
        text-align: center;
    }
</style>
